<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    // ...mapGetters({
    //   notificationMetadata: 'notifications/getMeta',
    // }),
    isXSalesAIPanelActive() {
      return this.$route.name === 'xsales_ai_index';
    },
  },
  methods: {
    openXSalesAIPanel() {
      if (this.$route.name !== 'xsales_ai_index') {
        this.$emit('openXSalesAIPanel');
      }
    },
  },
};
</script>

<template>
  <div class="mb-4">
    <button
      class="relative flex items-center justify-center w-10 h-10 p-0 my-2 rounded-lg text-slate-600 dark:text-slate-100 hover:bg-slate-25 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-slate-600"
      :class="{
        'bg-woot-50 dark:bg-slate-800 text-woot-500 hover:bg-woot-50':
          isXSalesAIPanelActive,
      }"
      @click="openXSalesAIPanel"
    >
      <fluent-icon
        icon="brain"
        icon-lib="custom"        
        :class="{
          'text-woot-500': isXSalesAIPanelActive,
        }"
      />
      <span
        v-if="unreadCount"
        class="text-black-900 bg-yellow-300 absolute -top-0.5 -right-1 text-xxs min-w-[1rem] rounded-full"
      >
        {{ unreadCount }}
      </span>
    </button>
  </div>
</template>
