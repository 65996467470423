/* eslint arrow-body-style: 0 */
import { frontendURL } from '../../../helper/URLHelper';
const SettingsWrapper = () => import('../settings/Wrapper.vue');
const XSalesView = () => import('./components/XSalesView.vue');

export const routes = [
  {
    path: frontendURL('accounts/:accountId/xsales-ai'),
    name: 'xsales-ai',
    component: XSalesView,
    meta: {
      permissions: ['administrator', 'agent', 'custom_role'],
    },
  },
];
